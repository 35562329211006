import { normalize } from "normalizr";
import { invoicesEntity } from "./schemas";
import { client } from ".";

// defining normalize return type manually as normalize has bad typescript support
// general types used here are defined in ./src/types.d.ts file
type Entities = { invoices: Record<number, Invoice> };
type Normalized = {
	entities: Entities;
	result: { data: number };
};
type NormalizedList = {
	entities: Partial<Entities>;
	result: {
		data: number[];
		meta: Meta;
	};
};

export async function fetchPage(params?: {
	page: number;
	type?: string;
	is_paid?: string;
}) {
	const { data } = await client.get(`/invoices`, { params });
	return normalize(data, { data: [invoicesEntity] }) as NormalizedList;
}

export async function show(id: number) {
	const { data } = await client.get(`/invoices/${id}`);
	return normalize(data, { data: invoicesEntity }) as Normalized;
}

export async function download(id: number) {
	const { data } = await client.post(`/invoices/${id}/export`);
	return data;
}
